@font-face{
    src: url(../../../../fonts/ITC\ Avant\ Garde\ Gothic\ Bold.otf);
    font-family: itc-avant-garde-gothic-bold;
}

@font-face{
    src: url(../../../../fonts/avenir-400.woff2);
    font-family: avenir-next-lt-pro;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, input, textarea {
    box-sizing: border-box;
    border: 0;
    font-size: 100%;
    margin: 0;
    outline: 0 none;
    padding: 0;
    vertical-align: baseline;
}
html {
    font-family: avenir-next-lt-pro;
    
}

.pricing-plans-row {
    display: flex;
    flex-direction: column;
}

.pricing-package {
    text-align: center;
    padding: 1.25rem 0;
    background-color: #f9f9f9;
    margin-bottom: 1.875rem;
    margin-top: 1rem;
    position: relative;
    box-shadow: 0 30px 30px -15px rgba(0,0,0,0.15);
    border-radius: 0.75rem;
    border: 1px solid #ddd;
}

/* heading */
.pricing-package--heading h3 {
    font-style: normal;
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 0.6875rem;
    font-family: inherit;
}

/* price */
.pricing-package--price {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
}


.pricing-package--price b {
    font-size: 2.25rem;
    line-height: 3rem;
}

.pricing-package--price i {
    font-size: 0.75rem;
    font-style: normal;
    line-height: 1.75rem;
    margin-top: 0;
}

/* button */
.pricing-package__buttons-wrapper {
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0 1.875rem;
}
.pricing-package__buttons {
    display: flex;
    flex-direction: column;
}

.pricing-package__buttons {
    margin: 0;
}

.button-primary{
    font-family: 'Lato',sans-serif;
    display: inline-block;
    padding: 1rem 2.5rem;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1;
    transition: background 100ms ease,border-color 100ms ease;
}

/* pricing description */
.pricing-package-description {
  padding: 0 1rem 1rem;
  line-height: 1.5;
}
.pricing-package-description p {
  color: #72848e;
}

p {
    /* display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px; */
    font-family: avenir-next-lt-pro;
    color: #393a3d;
}

/* features */
.pricing-featured_features {
    border-top: 1px solid #d8d8d8;
    padding-top: 1.375rem;
    margin-top: 1.25rem;
}
.js-toggleView__copy--animatable {
    display: block !important;
    height: 0;
    overflow: hidden;
}
.pricing-featured_features__chart-wrapper {
    display: none;
}
.pricing-featured_features__chart {
    text-align: left;
}

dl {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.pricing-featured_features dd.unavailable,
.pricing-featured_features dd.available {
    padding-left: 1.75rem;
}
.pricing-featured_features dd:first-child {
    margin-top: 0;
}
.pricing-featured_features dd {
    line-height: 1.75rem;
    margin: 0.625rem 0;
    position: relative;
}
dd {
    display: block;
    margin-inline-start: 40px;
}
.pricing-featured_features dd.unavailable, .pricing-featured_features dd.available {
    padding-left: 1.75rem;
}
.pricing-featured_features dd.available {
    display: flex;
    flex-direction: row;
}
.pricing-featured_features dd:first-child {
    margin-top: 0;
}
.pricing-featured_features dd {
    line-height: 1.75rem;
    margin: 0.625rem 0;
    position: relative;
}
dd {
    display: block;
    margin-inline-start: 40px;
}

.pricing-featured_features dd .availableSVG {
    height: 1.15rem;
    width: 1.15rem;
    position: absolute;
    left: 0.1875rem;
    top: 0.5rem;
}

.content_and_tooltip {
    height: 1rem;
    width: 1rem;
    position: relative;
    left: 0.5rem; 
    top: 0.4rem;
}


.toggle-present 
.pricing-package--price, 
.toggle-present 
.pricing-features__sticky 
.pricing-package--price {
    flex-direction: column;
}

.pricing-featured_features 
dd.unavailable {
    color: #c4c7c9;
}

/* feature buttons */
.pricing-features__button {
    font-family: 'Lato',sans-serif;
    font-size: 1rem;
    line-height: 1.75rem;
    border: 0;
    color: #0061d5;
    padding: 1rem 1.75rem;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-right: 3.4375rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    z-index: 1;
}

button {
    appearance: button;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
    box-sizing: border-box;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 6px;
    /* border-width: 2px;
    border-style: outset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial; */
}

.icon-circle_plus, 
.icon-circle_minus {
    margin-left: 5px;
}

.pricing-features__button--show,
.pricing-features__button--hide
{
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Additional classes for toggle Animation for feature_chat */
.js-toggleView__copy--expanded {
    will-change: height;
}

.js-toggleView__copy--animated-end {
    overflow: visible !important;
}

.autoHeight {
    height: auto;
}

.pricing-features--active .pricing-features__button--hide,
.pricing-featured_features--active .pricing-features__button--hide {
    display: inline !important;
}
.pricing-features--active .pricing-features__button--show,
.pricing-featured_features--active .pricing-features__button--show {
    display: none;
}

/* ADDON SECTIONS */
.section_bar {
    background-image: linear-gradient(266deg, #00D7D7, #28C86F);
    display: inline-block;
    height: 28px;
    color: #fff;
    font-weight: 600;
    z-index: 2;
    width: 100%;
    text-align: left;
    padding-left: 25px;
    font-size: 14px;
    padding-top: 0.35rem;
}

.addon_item_wrapper {
    position: relative;
}

.add-ons-toggle {
    right: 1em;
    bottom: 0.5em;
    cursor: pointer;
    z-index: 5;
}

p.addon_title,
p.addon_text {
    text-align: left;
    padding-left: 1.875rem;
    color: #393a3d;
}

p.addon_title {
    font-weight: bold;
    margin: 1em 0 0 0;
}

p.addon_text {
    margin: 0;
}


/* Mobile screen size UI layout */
.pricing-plans-group,
.pricing-sales-group
 {
    position: relative;
    margin-bottom: 3rem;
    margin-top: 0rem;
}

/* HEADER */
header,nav {
    display: block;
}
.pricing-hero {
    position: relative;
    font-family: itc-avant-garde-gothic-bold;
}
.container {
    max-width: 100%;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
}
.container:after {
    content: " ";
    display: block;
    clear: both;
}
.pricing-hero--header {
    text-align: center;
    position: relative;
    z-index: 1;
    margin: 0 auto;
}
.pricing-hero--header h1 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-left: 5vw;
    margin-right: 5vw;
}

/* PRICING NAVIGATION */
/* .pricing-navigation {
    margin-bottom: 2.5rem;
    margin-top: 0.5625rem;
} */
.container{
    max-width: 100%;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
}

/* PRICING NAVIGATION */
.pricing-navigation--tabs {
    border-bottom: 1px solid #ddd;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: center;
    position: relative;
    margin: 0 auto;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    display: -ms-grid;
    display: grid;
    /* max-width: 20.6875rem; */
}

.pricing-navigation--tabs::before {
    width: 50%;
}
.pricing-navigation--tabs::before {
    content: '';
    height: 0.3125rem;
    background-color: #0E8900;
    position: absolute;
    transition: transform .3s ease;
    bottom: -0.0625rem;
    transform: translateX(0);
    right: 0;
}
#pricing-navigation-toggle-checkbox:checked ~ .pricing-navigation--tabs::before {
    transform: translateX(-100%);
}
.pricing-navigation--button:nth-child(1) {
    -ms-grid-column: 1;
    grid-column: 1;
}
.pricing-navigation--button:nth-child(2) {
    -ms-grid-column: 2;
    grid-column: 2;
}
.pricing-navigation--button {
    font-family: 'Lato',sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #72848e;
    line-height: 4.25rem;
    white-space: nowrap;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 1rem;
}
.pricing-navigation-toggle-checkbox {
    display: none;
}
.pricing-navigation-tab-inactive {
    color: #72848e;
}

.pricing-navigation-tab-active {
    font-family: 'Lato',sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #000000;
    line-height: 4.25rem;
    white-space: nowrap;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 1rem;
}

/* PRICING TOGGLE */
.pricing-toggle {
    margin-top: 1.5rem;
    margin-bottom: 3.3125rem;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-grid-columns: minmax(0,1fr) minmax(0,64px) minmax(0,1fr);
    grid-template-columns: minmax(0,1fr) minmax(0,64px) minmax(0,1fr);
    /* display: none */
}
.pricing-navigation .pricing-toggle {
    display: -ms-grid;
    display: grid;
}
.pricing-toggle-button {
    font-family: 'Lato',sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    background: transparent;
    font-size: 1rem;
    padding: 0;
    border: 0;
    color: #000;
}
.pricing-toggle-checkbox {
    display: none;
}
#pricing-toggle-checkbox:checked ~ .pricing-toggle-button__before,
#pricing-toggle-checkbox ~ .pricing-toggle-button__after
 {
    color: #72848e;
}
#pricing-toggle-checkbox:checked ~ .pricing-toggle-button__after {
    color: inherit;
}
.pricing-toggle-button__after {
    justify-self: flex-start;
    -ms-grid-column: 3;
    grid-column: 3;
}
:focus{
    outline: none;
}

.pricing-toggle-discount {
    display: block;
    font-size: 0.75rem;
    position: absolute;
    left: 0;
    white-space: nowrap;
}

.pricing-toggle-button__before {
    justify-self: flex-end;
    -ms-grid-column: 1;
    grid-column: 1;
}
.pricing-toggle-label[for='pricing-toggle-checkbox'] {
    width: 2.75rem;
    height: 1.5rem;
    background: #b3ffb3;
    border-radius: 0.75rem;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    -ms-grid-column: 2;
    grid-column: 2;
}

label {
    cursor: default;
}
#pricing-toggle-checkbox:checked ~ .pricing-toggle-label[for='pricing-toggle-checkbox']::before {
    transform: translateX(100%);
}
.pricing-toggle-label[for='pricing-toggle-checkbox']::before {
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 1.5rem;
    width: 1.5rem;
    transition: transform 180ms ease;
    box-shadow: inset 0 0 0 1.5px #fff;
    background: #1aff1a;
    transform: translateX(0);
    left: 0;
}
 .monthly-price {
    text-decoration: line-through;
    color: #c3d1d9;
}
.pricing-package--price--monthly .annual-price, 
.pricing-package--price--monthly .annual-price-info {
    display: none;
}
.pricing-package--price--monthly .monthly-price {
    display: inline-block;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    color: #0E8900;
}
 .annual-price {
    font-weight: 700;
    font-style: normal;
    display: inline;
    text-decoration: none;
    color: #0E8900;
}

/* TABS CONTAINER */
.tabs-container {
    text-align: center;
    height: 41px;
    display: flex;
    justify-content: center;
    margin: 36px 5vw 0px;
}
.ctab {
    position: relative;
    background-color: transparent;
    display: inline-block;
    margin-right: 6vw;
}
.ctab:last-child {
    margin-right: 0;
}
/* The movement of the tab underline */
.tab-1.selected.k-tab:before {
    margin-left: 0px !important;
}
.tab-2.selected.k-tab:before {
    margin-left: 0px !important;
}

.tab-3.selected.k-tab:before {
    margin-left: 0px !important;
}

.tab-1.selected:before, 
.tab-2.selected:before, 
.tab-3.selected:before {
    content: "";
    display: inline-block;
    position: absolute;
    height: 4px;
    width: calc(100% + 10px);
    bottom: 0;
    border-radius: 2px;
    background-color: #2ca01c;
    top: 37px;
}

/* a:visited {
    color: #055393;
    text-decoration: none;
} */
.t-content {
    width: auto;
    display: inline-block;
    text-align: left;
    padding: 0 0 20px 0;
    position: relative;
    text-decoration: none !important;
}
.ctab.selected .t-content h3 {
    
    /* font-weight: 600 !important; */
    color: #393a3d !important;
    
}
/* a:visited {
    color: #055393;
} */
.rel {
    position: relative;
    text-align: left;
    margin-left: 22px;
}
.rd {
    display: none;
    visibility: hidden;
}
.ctab .a_update {
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: normal;
    color: #4a90e2;
    text-decoration: underline;
}
.tabs-container .ctab .rel img {
    margin-top: -5px;
}
img {
    width: auto;
    height: auto;
    border: 0;
    vertical-align: middle;
}

.disc--section {
    display: none;
    height: 76px;
    background: rgb(255, 255, 255);
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px 0px; */
}

.go_back {
    display: inline-block;
}

div#continue_without_add_ons {
    display: inline-block;
    padding-left: 20px;
}

div#show_process_bar{
    display: block;
}
div#hide_process_bar{
    display: none;
}
/* STEPPER TOGGLE */


.arranger--column {
    -webkit-box-orient: vertical; 
    -webkit-box-direction: normal;
    justify-content: space-around;
}

.arranger--inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.arranger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: 3em
}
.stepper {
    background-color: #fff;
    border-radius: 100px;
    -webkit-box-shadow: inset 0 0 0 1px #000;
    box-shadow: inset 0 0 0 1px #000;
    color: #000;
    -webkit-transition: -webkit-box-shadow .2s ease;
    transition: -webkit-box-shadow .2s ease;
    transition: box-shadow .2s ease;
    transition: box-shadow .2s ease,-webkit-box-shadow .2s ease;
}
.stepper-bttn.button--small {
    height: 1.875rem;
    background-color: transparent;
    border-style: none;
}
.button[type=button] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.stepper-bttn--dec {
    border-radius: 100px 0 0 100px;
}
.stepper-bttn {
    height: 2.5rem;
    width: 2.5rem;
}
button {
    cursor: pointer;
}
.elc-icon {
    font-family: NewYorkIcons;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    -mos-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    line-height: normal;
}
.stepper--small .stepper-count {
    height: 1.875rem;
    width: 1.875rem;
}
.stepper-count {
    font-weight: 700;
    height: 2.5rem;
    overflow: hidden;
    position: relative;
    width: 2.5rem;
}
.stepper-count__current, .stepper-count__next {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.stepper-bttn--inc {
    border-radius: 0 100px 100px 0;
    border-style: none;
}

/* CHECKOUT PAGE CONTAINER */
.CartWrapper {
    background-color: #f9f9f9;
    box-shadow: 0 30px 30px -15px rgba(0,0,0,0.15);
    border-radius: 0.75rem;
}

.pricing-package--recommended__wrapper {
    display: block;
    /* transform: translate(-50%,-50%); */
    position: absolute;
    top: -12px;
    left: 0px;
    width: 100%;
    /* left: 50%; */
    grid-row: 1;
    text-align: center;
    padding: 0 0.4rem;
}


.pricing-package--recommended__label {
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    background-color: #3fb87f;
    color: #fff;
    font-size: 0.625rem;
    width: calc(80% + 2px);
    line-height: 1.5rem;
    border-radius: 0.25rem;
    display: inline-block;
    /* position: absolute; */
    /* top: -15px; */
    /* left: 3em; */
}


/******************** MEDIA QUERIES ********************/
@media (min-width: 48em){
    .pricing-navigation--button {
        padding-left: 1.5625rem;
        padding-right: 1.5625rem;
    }
    .container {
        max-width: 100%;
        margin-left: 2.125rem;
        margin-right: 2.125rem;
    }
    .container:after {
        content: " ";
        display: block;
        clear: both;
    }
}

@media (min-width: 64em){
    input#tab-2:checked.toggle-present ~ .pricing-navigation .pricing-toggle {
        display: flex;
    }
    .pricing-toggle {
        margin: 0;
        justify-content: flex-end;
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-columns: auto minmax(0,4rem) auto;
        grid-template-columns: auto minmax(0,4rem) auto;
    }
    .pricing-navigation--tabs {
        justify-content: flex-start;
        margin: 0;
    }
    .pricing-navigation .container {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
    .pricing-package {
        display: -ms-grid;
        -ms-grid-rows: 3rem 5.75rem 6.6875rem 7.75rem 1fr;
        grid-template-rows: 3rem 5.75rem 6.6875rem 7.75rem auto auto auto;
        margin-left: 0;
        margin-right: 0;
    }

    .pricing-package--recommended__label {
        font-size: 0.75rem;
    }
   
    .pricing-plans-row {
        display: -ms-grid;
        display: grid;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        grid-gap: 0 1.875rem;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: repeat(3,1fr);
        height: auto;
        align-items: flex-start; /* ADDED */
        
    }
    /* .pricing-package:nth-child(1)::before {
        content:'';
        -ms-grid-column: 1;
        -ms-grid-row: 1;
        grid-column: 1;
        background-color: #F9F9F9;
        grid-row: 1/6;
        box-shadow: 0 30px 30px -15px rgba(0,0,0,0.15);
        border-radius: 0.75rem;
        border: 1px solid #ddd;
        -ms-grid-row-span: 7;
    }
    .pricing-package:nth-child(2)::before {
        content:'';
        -ms-grid-column: 2;
        grid-column: 2;
        background-color: #F9F9F9;
        grid-row: 1/6;
        box-shadow: 0 30px 30px -15px rgba(0,0,0,0.15);
        border-radius: 0.75rem;
        border: 1px solid #ddd;
         -ms-grid-row-span: 5;
    }
    .pricing-package:nth-child(3)::before {
        content:'';
        -ms-grid-column: 3;
        grid-column: 3;
        background-color: #F9F9F9;
        grid-row: 1/6;
        box-shadow: 0 30px 30px -15px rgba(0,0,0,0.15);
        border-radius: 0.75rem;
        border: 1px solid #ddd;
         -ms-grid-row-span: 5;
    } */

    /* .pricing-package > div:not(.pricing-package--recommended__wrapper) {
        margin-left: 1.875rem;
        margin-right: 1.875rem;
    } */

    .pricing-package--heading {
        -ms-grid-row: 1;
        grid-row: 1;
        padding-bottom: 0.625rem;
        -ms-grid-row-align: center;
        align-self: center;
        padding-top: 1.25rem;
    }

    .pricing-package--price {
        -ms-grid-row: 2;
        grid-row: 2;
        -ms-grid-row-align: center;
        align-self: center;
    }

    .pricing-package--price b {
        line-height: 2.25rem;
    }

    .pricing-package--price i {
        margin-top: 8px;
    }

    .pricing-package__buttons-wrapper {
        -ms-grid-row: 3;
        grid-row: 3;
    }

    .pricing-package-description {
        -ms-grid-row: 4;
        grid-row: 4;
        align-self: flex-start;
    }

    .pricing-featured_features {
        -ms-grid-row: 5;
        grid-row: 5;
        margin-top: 0;
        /* margin-bottom: 1.875rem; */
    }

    .pricing-package-addons {
        -ms-grid-row: 6;
        grid-row: 6;
        margin-top: 0;
        margin-bottom: 1.875rem;
    }

    .pricing-package-setups {
        -ms-grid-row: 7;
        grid-row: 7;
        margin-top: 0;
        margin-bottom: 1.875rem;
    }

    .pricing-featured_features__chart-wrapper {
        display: block !important;
        height: auto !important;
        overflow: visible !important;
    }

    .pricing-package:nth-child(1) > div {
        -ms-grid-column: 1;
        grid-column: 1;
    }

    .pricing-package:nth-child(2) > div {
        -ms-grid-column: 2;
        grid-column: 2;
    }

    .pricing-package:nth-child(3) > div {
        -ms-grid-column: 3;
        grid-column: 3;
    }
    .pricing-featured_features .pricing-features__button {
        display: none;
    }
    .pricing-navigation {
        -ms-grid-column: 1;
        grid-column: 1;
        /* margin-top: 1.25rem; */
    }
    .pricing-package--addons {
        -ms-grid-row: 6;
        grid-row: 6;
        margin-top: 0;
        margin-bottom: 1.875rem;
    }
}

@media (max-width: 1023px) and (min-width: 425px){
    .pricing-package {
        padding-left: calc((50% - (23.9375rem / 2)));
        padding-right: calc((50% - (23.9375rem / 2)));
    }
}


